import React from "react";
import {
  Box,
  Flex,
  Button,
  useColorMode,
  IconButton,
  Image,
  Container,
  Tooltip,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  Link,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { SunIcon, MoonIcon, HamburgerIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const WorkWithUsButton = () => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => navigate("/work-with-us")}
      colorScheme="pink"
      variant="solid"
      size="md"
      fontWeight="bold"
      px={6}
      _hover={{
        transform: "translateY(-2px)",
        boxShadow: "lg",
      }}
    >
      Work With Us
    </Button>
  );
};

const Header = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    onClose();
  };

  const buttonStyle = {
    variant: "ghost",
    size: "md",
    fontWeight: "medium",
    _hover: { bg: colorMode === "light" ? "gray.200" : "gray.700" },
  };

  return (
    <>
      <Box
        as={motion.nav}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        bg={colorMode === "light" ? "white" : "gray.800"}
        color={colorMode === "light" ? "gray.800" : "white"}
        boxShadow="md"
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex={10}
      >
        <Container maxW="container.xl">
          <Flex py={4} alignItems="center" justifyContent="space-between">
            <Image
              src="https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/LOGO_FINAL+(1080+x+900+px).png"
              alt="Stay Retro"
              height="50px"
              width="auto"
              cursor="pointer"
              onClick={() => navigate("/")}
            />
            <Flex alignItems="center">
              <Box display={{ base: "none", md: "flex" }} alignItems="center" mr={4}>
                <Button onClick={() => navigate("/")} {...buttonStyle} mr={2}>
                  HOME
                </Button>
                <Button onClick={() => navigate("/about")} {...buttonStyle} mr={2}>
                  ABOUT
                </Button>
                <Button onClick={() => navigate("/entertainment")} {...buttonStyle} mr={2}>
                  ENTERTAINMENT
                </Button>
                <Button onClick={() => navigate("/giveaway")} {...buttonStyle} mr={2}>
                  GIVEAWAY AND MAP
                </Button>
                <Link href="https://givebutter.com/8MgJGG" isExternal>
                  <Button
                    as={motion.button}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    colorScheme="purple"
                    variant="solid"
                    size="md"
                    mr={2}
                  >
                    Donate
                  </Button>
                </Link>
                <WorkWithUsButton />
              </Box>
              <Tooltip label="Toggle color mode">
                <IconButton
                  icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
                  onClick={toggleColorMode}
                  variant="ghost"
                  aria-label="Toggle color mode"
                  mr={2}
                />
              </Tooltip>
              <IconButton
                icon={<HamburgerIcon />}
                onClick={onOpen}
                variant="ghost"
                aria-label="Open menu"
                display={{ base: "flex", md: "none" }}
              />
            </Flex>
          </Flex>
        </Container>
      </Box>

      {/* Mobile Menu Drawer */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>
            <VStack spacing={4} align="stretch">
              <Button onClick={() => handleNavigation("/")} {...buttonStyle}>HOME</Button>
              <Button onClick={() => handleNavigation("/about")} {...buttonStyle}>ABOUT</Button>
              <Button onClick={() => handleNavigation("/entertainment")} {...buttonStyle}>
                ENTERTAINMENT
              </Button>
              <Button onClick={() => handleNavigation("/giveaway")} {...buttonStyle}>GIVEAWAY AND MAP</Button>
              <Link href="https://givebutter.com/8MgJGG" isExternal>
                <Button
                  as={motion.button}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  colorScheme="purple"
                  variant="solid"
                  size="md"
                  width="100%"
                >
                  Donate
                </Button>
              </Link>
              <WorkWithUsButton />
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Header;