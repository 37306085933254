import React from "react";
import { Box, Container, Heading, SimpleGrid, Image, useColorModeValue, VStack, Flex } from "@chakra-ui/react";
import { motion } from "framer-motion";

const MotionImage = motion(Image);

const sponsors = [
  {
    name: "Amazon Studios",
    logo: "https://z-assets.s3.us-west-1.amazonaws.com/sponsors/CC_Logo7.png",
    isPremium: true,
  },
  {
    name: "Sponsor 1",
    logo: "https://z-assets.s3.us-west-1.amazonaws.com/sponsors/CC_Logo1.png",
  },
  {
    name: "Sponsor 2",
    logo: "https://z-assets.s3.us-west-1.amazonaws.com/sponsors/CC_Logo2.png",
  },
  {
    name: "Sponsor 3",
    logo: "https://z-assets.s3.us-west-1.amazonaws.com/sponsors/CC_Logo3.png",
  },
  {
    name: "Sponsor 4",
    logo: "https://z-assets.s3.us-west-1.amazonaws.com/sponsors/CC_Logo4.png",
  },
  {
    name: "Sponsor 5",
    logo: "https://z-assets.s3.us-west-1.amazonaws.com/sponsors/CC_Logo5.png",
  },
  {
    name: "Sponsor 6",
    logo: "https://z-assets.s3.us-west-1.amazonaws.com/sponsors/CC_Logo6.png",
  },
  {
    name: "Sponsor 7",
    logo: "https://z-assets.s3.us-west-1.amazonaws.com/sponsors/Untitled+design+(2).png",
  },
  {
    name: "Procolo",
    logo: "https://z-assets.s3.us-west-1.amazonaws.com/sponsors/procolo.png",
  },
];

const SponsorSection = () => {
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const premiumSponsor = sponsors.find(s => s.isPremium);
  const nonPremiumSponsors = sponsors.filter(s => !s.isPremium);
  
  // Split sponsors into top four and bottom four
  const topSponsors = nonPremiumSponsors.slice(0, 4);
  const bottomSponsors = nonPremiumSponsors.slice(4);

  return (
    <Box bg={bgColor} py={16}>
      <Container maxW="container.xl">
        <VStack spacing={12}>
          <Heading as="h2" size="2xl" textAlign="center">
            Festival Sponsors
          </Heading>
          
          {/* Premium Sponsor (Amazon Studios) */}
          <Box width="100%" mb={12}>
            <MotionImage
              src={premiumSponsor.logo}
              alt={premiumSponsor.name}
              maxW={{ base: "300px", md: "400px" }} // Adjusted for mobile
              objectFit="contain"
              mx="auto"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            />
          </Box>

          {/* All Non-Premium Sponsors */}
          <VStack spacing={8} width="100%">
            {/* Top Sponsors */}
            <SimpleGrid 
              columns={[2, 3, 4]} 
              spacing={8} 
              width="100%" 
              justifyItems="center"
              alignItems="center"
            >
              {topSponsors.map((sponsor, index) => (
                <MotionImage
                  key={sponsor.name}
                  src={sponsor.logo}
                  alt={sponsor.name}
                  maxH="120px" // Uniform height for top sponsors
                  objectFit="contain"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                />
              ))}
            </SimpleGrid>
            
            {/* Bottom Sponsors (centered and smaller) */}
            <Flex justifyContent="center" width="100%">
              <SimpleGrid 
                columns={[2, 3, 4]} 
                spacing={8} 
                width="auto" 
                justifyItems="center"
                alignItems="center"
              >
                {bottomSponsors.map((sponsor, index) => (
                  <MotionImage
                    key={sponsor.name}
                    src={sponsor.logo}
                    alt={sponsor.name}
                    maxH="100px" // Smaller height for bottom sponsors
                    objectFit="contain"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: (index + topSponsors.length) * 0.1 }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  />
                ))}
              </SimpleGrid>
            </Flex>
          </VStack>
        </VStack>
      </Container>
    </Box>
  );
};

export default SponsorSection;