import React from "react";
import {
  Box,
  Heading,
  Text,
  Image,
  VStack,
  HStack,
  Link,
  Icon,
  useColorModeValue,
  SimpleGrid, // Import SimpleGrid
} from "@chakra-ui/react";
import { FaInstagram, FaFacebook, FaTwitter } from "react-icons/fa";
import { ExternalLinkIcon } from "@chakra-ui/icons"; // Import ExternalLinkIcon

const bands = [
  {
    name: "Top Shelf Brass Band",
    bio: "Since 2012, Top Shelf Brass Band has collaborated with a diverse array of artists, pushing the boundaries of their genre and expanding their artistic horizons. From local venues and community events in Riverside California to festivals and concert halls across the world, Top Shelf Brass Band leaves an indelible mark wherever they perform, inspiring countless listeners with their infectious rhythms and melodies.",
    links: [
      {
        label: "Instagram",
        url: "https://www.instagram.com/topshelfbrassband/profilecard/?igsh=MzRlODBiNWFlZA==",
        icon: FaInstagram,
      },
    ],
    image: "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/band_photos/Top+Shelf+Brass+Band.png",
  },
  {
    name: "The Front Yard",
    bio: "We're a Culver City Arts District neighborhood band that formed during the pandemic in an effort to preserve our sanity. It worked! mostly….",
    links: [
      {
        label: "Website",
        url: "http://www.monagan.com/now",
        icon: null,
      },
    ],
    image: "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/band_photos/The+Front+Yard.png",
  },
  {
    name: "NeonGray1 aka Tiffany Naiman",
    bio: "House bangers to darkwave, always energetic and gives the dancefloor what it wants, great grooves.",
    links: [],
    image: "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/band_photos/NeonGray1.png",
  },
  // {
  //   name: "The A-List",
  //   bio: "Connecting artists and audiences with top-tier performances and unforgettable experiences.",
  //   links: [
  //     {
  //       label: "Facebook",
  //       url: "https://www.facebook.com/ashjangda",
  //       icon: FaFacebook,
  //     },
  //     {
  //       label: "Twitter",
  //       url: "https://www.twitter.com/ashjangda",
  //       icon: FaTwitter,
  //     },
  //     {
  //       label: "Instagram",
  //       url: "https://www.instagram.com/ashjangda",
  //       icon: FaInstagram,
  //     },
  //   ],
  //   image: "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/band_photos/A_List.png",
  // },
  {
    name: "FlyZulu",
    bio: "Bringing vibrant beats and dynamic performances to energize the crowd.",
    links: [],
    image: "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/band_photos/FlyZulu.png",
  },
  {
    name: "DJ Medit8",
    bio: "Specializing in deep house, DJ Medit8 creates an immersive musical experience that takes listeners on a journey through pulsating rhythms and soulful melodies.",
    links: [],
    image: "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/band_photos/Untitled+design.png",
  },
];

const BandShowcase = () => {
  const bgColor = useColorModeValue("gray.100", "gray.700"); // Move useColorModeValue hook call here

  return (
    <Box bg={bgColor} p={8} borderRadius="lg">
      <VStack spacing={8}>
        <Heading as="h2" size="xl">
          Band Showcase
        </Heading>
        <SimpleGrid columns={[1, 2, 2]} spacing={10}>
          {bands.map((band, index) => (
            <Box key={index} borderWidth="1px" borderRadius="lg" overflow="hidden">
              <Image src={band.image} alt={band.name} />
              <Box p={6}>
                <Heading as="h3" size="md" mb={4}>
                  {band.name}
                </Heading>
                <Text mb={4}>{band.bio}</Text>
                <HStack spacing={4}>
                  {band.links.map((link, idx) =>
                    link.icon ? (
                      <Link
                        href={link.url}
                        isExternal
                        key={idx}
                        color="brand.500"
                        display="flex"
                        alignItems="center"
                      >
                        <Icon as={link.icon} mr={2} />
                        {link.label}
                        <ExternalLinkIcon mx="2px" />
                      </Link>
                    ) : (
                      <Link
                        href={link.url}
                        isExternal
                        key={idx}
                        color="brand.500"
                      >
                        {link.label}
                        <ExternalLinkIcon mx="2px" />
                      </Link>
                    )
                  )}
                </HStack>
              </Box>
            </Box>
          ))}
        </SimpleGrid>
      </VStack>
    </Box>
  );
};

export default BandShowcase;