import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  SimpleGrid,
  Image,
  VStack,
  Button,
  useColorModeValue,
  Link,
  Flex,
  Icon,
  Divider,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { FaMusic, FaPaintBrush, FaChild } from "react-icons/fa";
import EnhancedStageSchedules from "./EnhancedStageSchedules";
import VendorsShowcase from "./VendorsShowcase";
import BandShowcase from "./components/BandShowcase";

const MotionBox = motion(Box);

const entertainmentData = [
  {
    id: 1,
    title: "Live Music",
    description:
      "Enjoy performances from local bands and artists throughout the event.",
    image: "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/art_walk_photos/instagram_Art12.jpg",
    icon: FaMusic,
  },
  {
    id: 2,
    title: "Live Art Making",
    description:
      "Participate in interactive art workshops led by experienced artists.",
    image: "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/art_walk_photos/instagram_Art05.jpg",
    icon: FaPaintBrush,
  },
  {
    id: 3,
    title: "Kids Zone",
    description:
      "A dedicated area for children with fun art activities and games.",
    image: "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/art_walk_photos/instagram_Art07.jpg",
    icon: FaChild,
  },
];

const EntertainmentCard = ({ title, description, image, icon }) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.600", "gray.200");
  const iconColor = useColorModeValue("brand.500", "brand.300");

  return (
    <MotionBox
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      bg={bgColor}
      borderRadius="xl"
      overflow="hidden"
      boxShadow="2xl"
      transition="all 0.3s"
    >
      <Box position="relative">
        <Image
          src={image}
          alt={title}
          objectFit="cover"
          height="250px"
          width="100%"
        />
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(0,0,0,0.3)"
          transition="all 0.3s"
          opacity="0"
          _groupHover={{ opacity: 1 }}
        />
      </Box>
      <Box p={6}>
        <Flex align="center" mb={2}>
          <Icon as={icon} color={iconColor} boxSize={6} mr={2} />
          <Heading as="h3" size="md">
            {title}
          </Heading>
        </Flex>
        <Text color={textColor} mb={4}>
          {description}
        </Text>
      </Box>
    </MotionBox>
  );
};

const EntertainmentPage = () => {
  const bgGradient = useColorModeValue(
    "linear(to-b, gray.50, white)",
    "linear(to-b, gray.900, gray.800)"
  );

  return (
    <Box minHeight="100vh" bgGradient={bgGradient}>
      <Container maxW="container.xl" py={20}>
        <VStack spacing={20} as="section">
          <VStack spacing={8} textAlign="center">
            <Heading
              as="h1"
              size={{ base: "2xl", md: "4xl" }}
              fontWeight="bold"
              pt={10}
              bgGradient="linear(to-r, purple.400, pink.400, yellow.400)"
              bgClip="text"
              letterSpacing="tight"
            >
              Entertainment
            </Heading>
            <Text fontSize="2xl" maxW="3xl" lineHeight="tall" mb={8}>
              Immerse yourself in a world of creativity and fun with our diverse
              range of entertainment options.
            </Text>
            <Link
              href="https://culvercityartsdistrict.com/galleries/"
              isExternal
            >
              <Button
                rightIcon={<ExternalLinkIcon />}
                colorScheme="brand"
                size="lg"
                fontWeight="bold"
                px={8}
                py={6}
                _hover={{ transform: "translateY(-2px)", boxShadow: "lg" }}
              >
                Explore Culver City Arts District Galleries
              </Button>
            </Link>
          </VStack>

          <Box as="section" width="100%">
            <Heading as="h2" size="2xl" textAlign="center" mb={12}>
              Entertainment Highlights
            </Heading>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
              {entertainmentData.map((item) => (
                <EntertainmentCard key={item.id} {...item} />
              ))}
            </SimpleGrid>
          </Box>

          <Divider my={16} />

          <Box as="section" width="100%">
            <Heading as="h2" size="2xl" textAlign="center" mb={12}>
              Stage Schedules
            </Heading>
            <EnhancedStageSchedules />
          </Box>

          <Divider my={16} />

          <Box as="section" width="100%">
            <BandShowcase />
          </Box>

          <Divider my={16} />

          <Box as="section" width="100%">
            <VendorsShowcase />
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default EntertainmentPage;
