import React from "react";
import {
  Box,
  Container,
  VStack,
  Heading,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useColorModeValue,
  Image,
  SimpleGrid,
  Icon,
  Flex,
  Divider,
  Badge,
} from "@chakra-ui/react";
import {
  FaSubway,
  FaBus,
  FaBicycle,
  FaCar,
  FaTaxi,
  FaPalette,
  FaMusic,
  FaTruck,
  FaBeer,
  FaChild,
} from "react-icons/fa";

const AboutPage = () => {
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const textColor = useColorModeValue("gray.800", "gray.200");
  const accentColor = useColorModeValue("brand.500", "brand.300");
  const cardBg = useColorModeValue("white", "gray.800");

  const FeatureItem = ({ icon, text }) => (
    <Flex align="center" mb={2}>
      <Icon as={icon} color={accentColor} mr={2} />
      <Text fontSize="md">{text}</Text>
    </Flex>
  );

  return (
    <Box as="section" bg={bgColor} py={20} mt={20}>
      <Container maxW="container.xl">
        <VStack spacing={16} align="stretch">
          <Box textAlign="center">
            <Heading
              as="h1"
              size="3xl"
              mb={4}
              bgGradient={`linear(to-r, ${accentColor}, purple.500)`}
              bgClip="text"
            >
              Culver City Art Walk and Roll Festival
            </Heading>
           
          </Box>



          <Box
            bg={cardBg}
            p={10}
            borderRadius="xl"
            boxShadow="xl"
            position="relative"
            overflow="hidden"
          >
            <Box
              position="absolute"
              top="-20px"
              right="-20px"
              bg={accentColor}
              w="100px"
              h="100px"
              transform="rotate(45deg)"
            />
            <Heading as="h2" size="2xl" mb={6} color={accentColor}>
              IN THE HEART OF THE CULVER CITY ARTS DISTRICT
            </Heading>
            <Text fontSize="xl" mb={8} lineHeight="tall">
              We're turning up the volume! Culver City Arts District and Arts
              District Residents Association are proud to present this year's
              rockin' Art Walk and Roll Festival.
            </Text>
            <Text fontSize="xl" fontWeight="bold" mb={4}>
              This FREE Festival will feature:
            </Text>
            <SimpleGrid columns={[2, 3, 5]} spacing={6} mb={8}>
              <FeatureItem icon={FaPalette} text="Distinguished artists" />
              <FeatureItem icon={FaMusic} text="Live music" />
              <FeatureItem icon={FaTruck} text="Eclectic food trucks" />
              <FeatureItem icon={FaBeer} text="Beer gardens" />
              <FeatureItem icon={FaChild} text="Kid's activities" />
            </SimpleGrid>
            <Divider my={8} />
            <Text fontSize="lg" mb={4} lineHeight="tall">
              Events will take place throughout Culver City Arts District,
              including a designated street closure that stretches along the
              Arts District (Washington Blvd will be closed between La Cienega
              Ave and Hutchison Ave).
            </Text>
            <Text fontSize="lg" mb={4} lineHeight="tall">
              Visit established art galleries with special installations,
              explore the popup marketplace featuring arts and crafts vendors,
              and enjoy the neighborhood block party.
            </Text>
            {/* <Text fontSize="lg" mb={4} lineHeight="tall">
              Plus, experience a whole new world of electric mobility by trying
              out the latest electric scooters and electric bikes on our
              dedicated test track. Or bring your own e-scooter, e-bike,
              e-board, or other e-ride for an e-exploration of all the
              happenings throughout the Arts District!
            </Text> */}
          </Box>

          <Accordion allowMultiple defaultIndex={[0, 1]}>
            <AccordionItem>
              <h2>
                <AccordionButton
                  _expanded={{ bg: accentColor, color: "white" }}
                >
                  <Box flex="1" textAlign="left">
                    <Heading as="h3" size="lg">
                      Street Closure Information
                    </Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Box p={4} bg={cardBg} borderRadius="md" boxShadow="md">
                  <Text fontWeight="bold" mb={4}>
                    Closure Area:
                  </Text>
                  <Badge colorScheme="purple" mb={4}>
                    Washington Boulevard between McManus Avenue and Caroline Ave
                  </Badge>
                  <Text fontWeight="bold" mb={2}>
                    Access for Residents:
                  </Text>
                  <Text mb={2}>
                    • South of Washington Blvd: via Helms Avenue, Sentney
                    Avenue, and Reid Avenue
                  </Text>
                  <Text mb={4}>
                    • North of Washington Blvd: via Venice Boulevard
                  </Text>
                  <Text fontWeight="bold" mb={2}>
                    Detours:
                  </Text>
                  <Text mb={2}>• Eastbound: National Boulevard</Text>
                  <Text mb={2}>• Westbound: La Cienega Boulevard</Text>
                </Box>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton
                  _expanded={{ bg: accentColor, color: "white" }}
                >
                  <Box flex="1" textAlign="left">
                    <Heading as="h3" size="lg">
                      Getting Here
                    </Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} mt={10}>
                {" "}
                {/* Increased margin-top for spacing */}
                <SimpleGrid columns={[1, 2, 3]} spacing={8}>
                  <Box bg={cardBg} p={6} borderRadius="md" boxShadow="md">
                    <Flex align="center" mb={4}>
                      <Icon
                        as={FaSubway}
                        boxSize={10}
                        color={accentColor}
                        mr={4}
                      />
                      <Heading as="h4" size="md">
                        Metro
                      </Heading>
                    </Flex>
                    <Text mb={2}>• Take the Expo Line</Text>
                    <Text mb={2}>• Get off at Culver City Station</Text>
                    <Text fontWeight="bold">• FREE all day Saturday!</Text>
                  </Box>

                  <Box bg={cardBg} p={6} borderRadius="md" boxShadow="md">
                    <Flex align="center" mb={4}>
                      <Icon
                        as={FaBus}
                        boxSize={10}
                        color={accentColor}
                        mr={4}
                      />
                      <Heading as="h4" size="md">
                        Bus
                      </Heading>
                    </Flex>
                    <Text mb={2}>• Take Culver City Bus #1</Text>
                    <Text mb={2}>• Get off at Washington / Landmark</Text>
                    <Text>• Or get off at Washington / Comey</Text>
                  </Box>

                  <Box bg={cardBg} p={6} borderRadius="md" boxShadow="md">
                    <Flex align="center" mb={4}>
                      <Icon
                        as={FaBicycle}
                        boxSize={10}
                        color={accentColor}
                        mr={4}
                      />
                      <Heading as="h4" size="md">
                        Bike/Scooter
                      </Heading>
                    </Flex>
                    <Text mb={2}>
                      • Bike parking available at Culver City Metro Station and
                      Art Walk & Roll
                    </Text>
                    <Text mb={2}>• Scooter sharing: Bird, Lime</Text>
                    <Text>• Bike valet on site</Text>
                  </Box>

                  <Box bg={cardBg} p={6} borderRadius="md" boxShadow="md">
                    <Flex align="center" mb={4}>
                      <Icon
                        as={FaTaxi}
                        boxSize={10}
                        color={accentColor}
                        mr={4}
                      />
                      <Heading as="h4" size="md">
                        Ride-sharing
                      </Heading>
                    </Flex>
                    <Text mb={2}>
                      • Drop-off at Washington Blvd / Landmark St
                    </Text>
                    <Text>• Or at La Cienega Ave / Melvil St</Text>
                  </Box>

                  <Box bg={cardBg} p={6} borderRadius="md" boxShadow="md">
                    <Flex align="center" mb={4}>
                      <Icon
                        as={FaCar}
                        boxSize={10}
                        color={accentColor}
                        mr={4}
                      />
                      <Heading as="h4" size="md">
                        Parking
                      </Heading>
                    </Flex>
                    <Text mb={2}>
                      • La Cienega/Jefferson Station Park & Ride
                    </Text>
                    <Text mb={2}>• Public parking lots</Text>
                    <Text>• Metered street parking</Text>
                  </Box>
                </SimpleGrid>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </VStack>
      </Container>
    </Box>
  );
};

export default AboutPage;
