import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Image,
  Flex,
  Container,
  Heading,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  IconButton,
  useDisclosure,
  SimpleGrid,
  useBreakpointValue,
  Button,
} from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronLeftIcon, ChevronRightIcon, CloseIcon } from "@chakra-ui/icons";

const MotionFlex = motion(Flex);

const images = [
  "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/art_walk_photos/instagram_Art12.jpg",
  "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/art_walk_photos/instagram_Art05.jpg",
  "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/art_walk_photos/instagram_Art07.jpg",
  "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/art_walk_photos/instagram_Art01.jpg",
  "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/art_walk_photos/instagram_Art10.jpg",
  "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/art_walk_photos/instagram_Art11.jpg",
  "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/art_walk_photos/instagram_Art13.jpg",
  "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/art_walk_photos/instagram_Art08.jpg",
  "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/art_walk_photos/instagram_Art06.jpg",
  "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/art_walk_photos/instagram_Art09.jpg",
];

const PhotoGallery = () => {
  const [duplicatedImages, setDuplicatedImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const scrollRef = useRef(null);
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [visibleImages, setVisibleImages] = useState(3);

  useEffect(() => {
    setDuplicatedImages([...images, ...images]);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      const scrollContainer = scrollRef.current;
      if (scrollContainer) {
        const scrollWidth = scrollContainer.scrollWidth;
        const animateScroll = () => {
          if (scrollContainer.scrollLeft >= scrollWidth / 2) {
            scrollContainer.scrollLeft = 0;
          } else {
            scrollContainer.scrollLeft += 1.5;
          }
        };

        const animationId = setInterval(animateScroll, 20);

        return () => clearInterval(animationId);
      }
    }
  }, [duplicatedImages, isMobile]);

  const handleImageClick = useCallback(
    (index) => {
      setCurrentImageIndex(index % images.length);
      onOpen();
    },
    [onOpen]
  );

  const handlePrevious = useCallback(() => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  }, []);

  const handleNext = useCallback(() => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  }, []);

  const loadMoreImages = () => {
    setVisibleImages((prevVisible) => Math.min(prevVisible + 3, images.length));
  };

  return (
    <Box bg={bgColor} py={isMobile ? 16 : 8} overflow="hidden">
      <Container maxW="container.xl" mb={isMobile ? 8 : 4}>
        <Heading
          as="h2"
          size="2xl"
          textAlign="center"
          bgGradient="linear(to-r, brand.500, accent.500)"
          bgClip="text"
          mb={4}
        >
          Gallery
        </Heading>
        <Text
          fontSize="xl"
          textAlign="center"
          color={textColor}
          mb={isMobile ? 8 : 4}
        >
          Explore the vibrant artworks and moments from our recent Art Walk
        </Text>
      </Container>

      {isMobile ? (
        <>
          <SimpleGrid columns={[1, 2, 3]} spacing={6} px={4}>
            {images.slice(0, visibleImages).map((src, index) => (
              <Box
                key={`${src}-${index}`}
                h="250px"
                borderRadius="lg"
                overflow="hidden"
                boxShadow="xl"
                onClick={() => handleImageClick(index)}
                cursor="pointer"
              >
                <Image
                  src={src}
                  alt={`Art Walk Gallery ${index + 1}`}
                  objectFit="cover"
                  w="100%"
                  h="100%"
                  transition="transform 0.3s ease-in-out"
                  _hover={{ transform: "scale(1.05)" }}
                  loading="lazy"
                />
              </Box>
            ))}
          </SimpleGrid>
          {visibleImages < images.length && (
            <Flex justifyContent="center" mt={8}>
              <Button onClick={loadMoreImages} colorScheme="purple">
                Load More
              </Button>
            </Flex>
          )}
        </>
      ) : (
        <MotionFlex
          ref={scrollRef}
          overflowX="hidden"
          whiteSpace="nowrap"
          css={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",
          }}
          h="500px"
          alignItems="center"
        >
          {duplicatedImages.map((src, index) => (
            <Box
              key={`${src}-${index}`}
              minW="600px"
              h="450px"
              mr={6}
              borderRadius="lg"
              overflow="hidden"
              boxShadow="xl"
              flex="0 0 auto"
              onClick={() => handleImageClick(index)}
              cursor="pointer"
            >
              <Image
                src={src}
                alt={`Art Walk Gallery ${index + 1}`}
                objectFit="cover"
                w="100%"
                h="100%"
                transition="transform 0.3s ease-in-out"
                _hover={{ transform: "scale(1.05)" }}
                loading="lazy"
              />
            </Box>
          ))}
        </MotionFlex>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent bg="rgba(0,0,0,0.9)">
          <ModalBody
            p={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              icon={<CloseIcon />}
              position="absolute"
              top={4}
              right={4}
              onClick={onClose}
              aria-label="Close modal"
              colorScheme="whiteAlpha"
              variant="ghost"
              size="lg"
            />
            <IconButton
              icon={<ChevronLeftIcon />}
              position="absolute"
              left={4}
              onClick={handlePrevious}
              aria-label="Previous image"
              colorScheme="whiteAlpha"
              variant="ghost"
              size="lg"
            />
            <AnimatePresence mode="wait">
              <motion.div
                key={currentImageIndex}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Image
                  src={images[currentImageIndex]}
                  alt={`Art Walk Gallery ${currentImageIndex + 1}`}
                  maxH="90vh"
                  maxW="90vw"
                  objectFit="contain"
                />
              </motion.div>
            </AnimatePresence>
            <IconButton
              icon={<ChevronRightIcon />}
              position="absolute"
              right={4}
              onClick={handleNext}
              aria-label="Next image"
              colorScheme="whiteAlpha"
              variant="ghost"
              size="lg"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PhotoGallery;
